<template>
  <div>
      <!-- TABLE ACTION ROW -->
      <div class="flex flex-wrap justify-between items-center">
        <!-- ITEMS PER PAGE -->
        <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2"
                >{{
                  currentPage * paginationPageSize - (paginationPageSize - 1)
                }}
                -
                {{
                  pendingPaymentsSchools.length - currentPage * paginationPageSize > 0
                    ? currentPage * paginationPageSize
                    : pendingPaymentsSchools.length
                }}
                de {{ pendingPaymentsSchools.length }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>

            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                <span>50</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                <span>100</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                <span>150</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        <div
          class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
          <vs-input
            class="mb-4 md:mb-0 mr-4"
            v-model="searchQuery"
            @input="updateSearchQuery"
            placeholder="Buscar..."/>
          <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()"
            >Exportar</vs-button>
        </div>

      </div>
      <ag-grid-vue
        ref="agGridTable"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="pendingPaymentsSchools"
        @first-data-rendered="onFirstDataRendered"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
        :modules="modules">
      </ag-grid-vue>
      <ag-grid-vue style="height: 60px; flex: none;"
          class="ag-theme-material w-100 ag-grid-table total"
          :gridOptions="bottomGridOptions"
          :headerHeight="0"
          :columnDefs="columnDefs"
          :floatingFiltersHeight="0"
          :rowData="summaryFooter"
          :modules="modules">
        </ag-grid-vue>
      <vs-pagination
        :total="totalPages"
        :max="maxPageNumbers"
        v-model="currentPage"
      />

      <vs-popup fullscreen title="Recibo" :active.sync="popupActive">
        <iframe v-if="isPdf" :src="receiptUrl+'#toolbar=0'" frameborder="0" style="width:100%; height:100%;"></iframe>
        <vs-row v-else vs-align="center" vs-type="flex" vs-justify="space-around" vs-w="12">
            <img :src="receiptUrl" />
        </vs-row>
      </vs-popup>

      <popup-detail
          :data="popupData"
          :title="popupTitle"
          :popupActive="popupActiveDetails"
          @updatePopupActive="updatePopupActive(false)"
      ></popup-detail>
  </div>
</template>
<script>
import { AgGridVue } from "@ag-grid-community/vue";
import {AllCommunityModules} from '@ag-grid-community/all-modules';
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import CellRendererActions from "./components/CellRendererActionsProcessedRequests.vue";
// Store Modules
import moduleReport from "@/store/report/moduleReport.js";
import moduleDataListInstitutions from "@/store/data-list-institutions/moduleDataList.js"
import moduleUserManagement from "@/store/user-management/moduleUserManagement.js"
import PopupDetail from "./components/PaymentAdvanceRequestDetails.vue"

export default {
  components: {
      AgGridVue,
      CellRendererActions,
      PopupDetail
  },
  data() {
      return {
          modules: AllCommunityModules,
          pendingPaymentsSchools: [],

          //Sidebar
          paymentsSidebar: false,
          paymentsSidebarActive: false,
          sidebarData: {},

          // Popup Details
          popupData: [],
          popupTitle: '',
          popupActiveDetails: false,

          //MockData
          mockData: [
              {
                  "id_advance_request": "1",
                  "school_name": "School Test Advance Payment",
                  "school_admin": "Admin School 123",
                  "period": "November 2023",
                  "request_date": "15/11/2023",
                  "base_amount": 15000,
                  "items": 2.5,
                  "items2": 375,
                  "items3": 1,
                  "items4": 150,
                  "tax": 7,
                  "national_tax_amount": 36.75,
                  "final_amount_collector": 14438.25
              }
          ],

          //Table
          searchQuery: "",
          gridOptions: {
              alignedGrids: [],
              suppressHorizontalScroll: true,
              context: {}
            },
          maxPageNumbers: 7,
          gridApi: null,
          defaultColDef: {
              sortable: true,
              editable: false,
              resizable: true,
              suppressMenu: true,
          },

          groupHeaderHeight: 150,
          headerHeight: 100,

          columnDefs: [
              {
                  headerName: "Ticket",
                  field: "id_advance_request",
                  width: 75,
                  filter: true,
              },
              {
                  headerName: "Escuela",
                  field: "school_name",
                  width: 180,
                  filter: true
              },
              {
                  headerName: "Período",
                  field: "period",
                  width: 140,
                  filter: true,
                  valueFormatter: params => this.resolvePeriod(params.data.period)
              },
              {
                  headerName: "Fecha de Solicitud",
                  field: "request_date",
                  width: 140,
                  filter: true,
                  comparator: this.myDateComparator,
              },
              {
                  headerName: "Total por Adelantos",
                  field: "base_amount",
                  width: 150,
                  filter: true,
                  valueFormatter: params => this.$options.filters.currencySign(params.data.base_amount, '$ '),
              },
              {
                  headerName: "Cargo por Adelanto",
                  width: 180,
                  filter: true,
                  valueFormatter: params => `${this.$options.filters.currencySign(params.data.items.find(i => i.code_item == "CADVANCED").amount, '$ ')} ${params.data.items.find(i => i.code_item == "CADVANCED").percent != undefined ? '(' + this.$options.filters.currencyPercentage(params.data.items.find(i => i.code_item == "CADVANCED").percent, ' %') + ')' : ''}`,
                  valueGetter: params => params.data.items.find(i => i.code_item == "CADVANCED").amount
              },
              {
                  headerName: "Cargo por Procesamiento",
                  width: 180,
                  filter: true,
                  valueFormatter: params => `${this.$options.filters.currencySign(params.data.items.find(i => i.code_item == "CSERVICE2").amount, '$ ')} ${params.data.items.find(i => i.code_item == "CSERVICE2").percent != undefined ? '(' + this.$options.filters.currencyPercentage(params.data.items.find(i => i.code_item == "CSERVICE2").percent, ' %') + ')' : ''}`,
                  valueGetter: params => params.data.items.find(i => i.code_item == "CSERVICE2").amount
              },
              {
                  headerName: "ITBMS",
                  field: "national_tax_amount",
                  width: 150,
                  filter: true,
                  valueFormatter: params => `${this.$options.filters.currencySign(params.data.national_tax_amount, '$ ')} ${params.data.national_tax_percent != undefined ? '(' + this.$options.filters.currencyPercentage(params.data.national_tax_percent, ' %') + ')' : ''}`,
              },
              {
                  headerName: "Total a Adelantar",
                  field: "final_amount_collector",
                  width: 150,
                  filter: true,
                  valueFormatter: params => this.$options.filters.currencySign(params.data.final_amount_collector, '$ '),
              },
              {
                  headerName: "Fecha de Pago",
                  field: "pay_date",
                  width: 140,
                  filter: true,
                  comparator: this.myDateComparator,
              },
              {
                headerName: "Acciones",
                field: "receipt",
                filter: false,
                width: 120,
                cellRendererFramework: 'CellRendererActions',
                cellRendererParams: {
                  showPopup: this.showPopup.bind(this),
                  showPopupDetails: this.showPopupDetails.bind(this)
                }
              },

          ],

          // Popup Receipt
          popupActive: false,
          isPdf: false,
          receiptUrl: '',

          // Bottom Summary Row
          bottomGridOptions: {
            alignedGrids: [],
            defaultColDef: {
                editable: false,
                sortable: true,
                resizable: true,
                filter: true,
                flex: 1,
                floatingFiltersHeight: 0
            }
          },

          summaryFooter: [],
      }
  },
  beforeCreate() {
      if(!moduleReport.isRegistered) {
          this.$store.registerModule('reportModule', moduleReport)
          moduleReport.isRegistered = true
      }
      if(!moduleDataListInstitutions.isRegistered) {
          this.$store.registerModule('dataListInstitutions', moduleDataListInstitutions)
          moduleDataListInstitutions.isRegistered = true
      }
      if(!moduleUserManagement.isRegistered) {
          this.$store.registerModule('userManagement', moduleUserManagement)
          moduleUserManagement.isRegistered = true
      }
  },
  created() {
      this.$store.dispatch(`dataListInstitutions/getSchools`)
          .then(() => {
              this.loadPendingPaymentsTables()
          })
          .catch(err => console.log(err))
  },
  methods: {
      showPopup(isPdf, receipt) {
        this.isPdf = isPdf
        this.receiptUrl = receipt
        this.popupActive = true
      },
      isDate(val) {
          return val instanceof Date && !isNaN(val.valueOf())
      },
      loadPendingPaymentsTables() {
          // this.pendingPaymentsSchools = this.mockData
          this.summaryFooter = [];

          this.$store.dispatch("reportModule/getSchoolAdvanceRequests", this.getQueryParams())
              .then(response => {
                  if (response.data.length > 0) {
                      let s = JSON.parse(JSON.stringify(response.data));
                      let s_footer = {};
                      s_footer.id_advance_request = "Total";
                      s_footer.base_amount = 0;
                      s_footer.items = [{code_item: 'CADVANCED', amount: 0}];
                      s_footer.items.push({code_item: 'CSERVICE2', amount: 0});
                      s_footer.national_tax_amount = 0;
                      s_footer.final_amount_collector = 0;

                      s.forEach((element) => {
                          element.request_date = element.request_date ? new Date(element.request_date).toLocaleDateString("es-AR", this.$localeDateOptions) : element.request_date;
                          element.pay_date = element.pay_date ? new Date(element.pay_date).toLocaleDateString("es-AR", this.$localeDateOptions) : element.pay_date;
                          element.school_name = this.$store.getters['dataListInstitutions/getSchoolById'](element.id_establishment).name

                          s_footer.base_amount += element.base_amount;
                          s_footer.items.find(i => i.code_item == 'CADVANCED').amount += element.items.find(i => i.code_item == 'CADVANCED').amount;
                          s_footer.items.find(i => i.code_item == 'CSERVICE2').amount += element.items.find(i => i.code_item == 'CSERVICE2').amount;
                          s_footer.national_tax_amount += element.national_tax_amount;
                          s_footer.final_amount_collector += element.final_amount_collector;
                      })

                      this.summaryFooter = [s_footer];
                      this.pendingPaymentsSchools = s;
                  }
              })
              .catch(err => console.log(err))
      },
      toggleDataSidebar(val=false) {
          this.paymentsSidebarActive = val
      },
      openSideBar() {
          this.toggleDataSidebar(true);
      },
      updateSearchQuery(val) {
          this.gridApi.setQuickFilter(val);
      },
      myDateComparator(date1, date2) {
          var date1Number = this.monthToComparableNumber(date1);
          var date2Number = this.monthToComparableNumber(date2);
          if (date1Number === null && date2Number === null) {
              return 0;
          }
          if (date1Number === null) {
              return -1;
          }
          if (date2Number === null) {
              return 1;
          }
          return date1Number - date2Number;
      },
      monthToComparableNumber(date) {
          if (date === undefined || date === null || date.length !== 10) {
              return null;
          }
          var yearNumber = date.substring(6, 10);
          var monthNumber = date.substring(3, 5);
          var dayNumber = date.substring(0, 2);
          var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
          return result;
      },
      resolvePeriod(period) {
          if(!period) return
          const p = new Date(period)
          return p.toLocaleString('default', { month: 'long' }) + ' ' + p.getFullYear()
      },
      showPopupDetails(requestData) {
        this.getRequestDetail(requestData.id_advance_request)
      },
      updatePopupActive(val) {
        this.popupActiveDetails = val
      },
      getQueryParams() {
        return `status=1`
      },
      getRequestDetail(id) {
        if (!id) return

        this.$store.dispatch("reportModule/getAdvanceRequestDetail", id)
          .then(response => {
            if (!response.status) return

            response.data.detail.forEach(detail => {
              detail.student_display_name = detail.student_name + ' ' + detail.student_last_name
              detail.guardian_display_name = detail.guardian_name + ' ' + detail.guardian_last_name
              detail.due_date = new Date(detail.expiration_date).toLocaleDateString("es-AR", this.$localeDateOptions)
              detail.expiration_date = this.getPeriodByExpirationDate(detail.expiration_date)
            })

            this.popupData = response.data.detail
            this.popupTitle = `Detalle de solicitud #${id}`
            this.popupActiveDetails = true
          })
          .catch(error => console.log(error))
      },
      getPeriodByExpirationDate(expirationDate) {
        return this.$store.getters['reportModule/getPeriodTextByExpirationDate'](expirationDate)
      },
      onFirstDataRendered: function() {
        this.gridColumnApi.autoSizeAllColumns();
      },
  },
  watch: {
      paymentsSidebarActive: function () {
          this.toggleDataSidebar(this.paymentsSidebarActive)
      },
      "$store.state.windowWidth"(val) {
          if (val <= 576) {
              this.maxPageNumbers = 7;
          }
      },
  },
  computed: {
      paginationPageSize() {
          if (this.gridApi) return this.gridApi.paginationGetPageSize();
          else return 50;
      },
      totalPages() {
          if (this.gridApi) return this.gridApi.paginationGetTotalPages();
          else return 0;
      },
      currentPage: {
          get() {
              if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
              else return 1;
          },
          set(val) {
              this.gridApi.paginationGoToPage(val - 1);
          },
      },
  },
  mounted() {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;

      if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(
          ".ag-header-container"
      );
      header.style.left =
          "-" + String(Number(header.style.transform.slice(11, -3)) + 9) + "px";
      }
  },
  beforeMount() {
    this.gridOptions.alignedGrids.push(this.bottomGridOptions);
    this.bottomGridOptions.alignedGrids.push(this.gridOptions);

    this.gridOptions.context = {
      componentParent: this
    };
  },
}
</script>
<style>
  .ag-header-cell-label .ag-header-cell-text{
    overflow: visible;
    text-overflow: clip;
    white-space: normal;
  }
  .ag-grid-table.total .ag-row {
    font-weight: 700;
  }
  .ag-grid-table.total .ag-header {
    border: none;
  }
</style>
